import { Badge, Icon, IconName } from '@hubtype/ui-react-web'
import { CSSProperties, memo } from 'react'
import { Connection, HandleProps, Position } from 'reactflow'

import {
  ButtonFields,
  ContentId,
  ContentType,
  ElementFields,
  TopContentFields,
} from '../../../domain/models/content-fields'
import ConnectionHandleIcon from '../../assets/connection-handle.svg'
import { useBotConfig } from '../../custom-hooks/use-bot-config'
import { useFeedbackMessage } from '../../custom-hooks/use-feedback-message'
import {
  COLORS,
  TextBodySmall,
  TextBodySmallBold,
  TextSmall,
  TextSmallBold,
  TextSmallExtra,
} from '../base'
import { createFollowUpId, getTargetHandleId } from '../edges/edge-utils'
import { NodeHandleType } from './constants'
import { HandleType, TriangleHandle } from './handle-styles'
import { Markdown, MarkdownText } from './markdown/markdown'
import {
  Border,
  ButtonContainer,
  ClosedElement,
  Code,
  ConditionContainer,
  Header,
  OpenedElement,
  StyledInputList,
  Title,
} from './node-styles'

interface NodeHeaderProps {
  data: TopContentFields
  icon?: string
  iconComponent?: React.ReactNode
  title?: string
  channelIcon?: string
  hideCode?: boolean
}

export const NodeHeader = memo((props: NodeHeaderProps): JSX.Element => {
  return (
    <>
      <NodeHeaderCode {...props} />
      <Header>
        <Title>{props.title}</Title>
        <div>
          {props.channelIcon && (
            <Icon icon={props.channelIcon} size='large' prefix='fab' />
          )}
          {props.icon && <Icon icon={props.icon} size='large' />}
          {props.iconComponent && props.iconComponent}
        </div>
      </Header>
    </>
  )
})

export const NodeHeaderCode = memo((props: NodeHeaderProps): JSX.Element => {
  const hasErrors =
    props.data.errors.showErrors &&
    (props.data.errors.fieldErrors.length > 0 ||
      props.data.errors.hasDuplicatedCode)
  return (
    <Code>
      {!props.hideCode && (
        <TextSmallExtra $withEllipsis>{props.data.code}</TextSmallExtra>
      )}
      {hasErrors && (
        <Icon icon='exclamation-triangle' size='medium' prefix='fad' />
      )}
    </Code>
  )
})

interface HandlesProps {
  contentId: ContentId
  hasFollowUp?: boolean
}

export const Handles = memo((props: HandlesProps): JSX.Element => {
  const { isBotActionConnectable } = useBotConfig()
  const isConnectableWithBotAction =
    isBotActionConnectable || props.contentId.type === ContentType.HANDOFF

  return (
    <>
      <StyledHandle
        handleType={HandleType.TARGET}
        type={NodeHandleType.Target}
        position={Position.Left}
        id={getTargetHandleId(props.contentId)}
        isConnectableWithBotAction={isConnectableWithBotAction}
      />
      {props.hasFollowUp && (
        <StyledHandle
          handleType={HandleType.FOLLOWUP}
          type={NodeHandleType.Source}
          position={Position.Right}
          id={createFollowUpId(props.contentId.id)}
          isConnectableWithBotAction={isConnectableWithBotAction}
        />
      )}
    </>
  )
})

export const InputHandles = (props: HandlesProps): JSX.Element => {
  return (
    <StyledHandle
      handleType={HandleType.INPUT}
      type={NodeHandleType.Source}
      position={Position.Right}
      id={createFollowUpId(props.contentId.id)}
    />
  )
}

interface CarouselElementProps {
  element: ElementFields
  isCarouselSelected: boolean
  isElementSelected: boolean
  index: number
  selectElement: () => void
}
export const CarouselElement = memo(
  ({
    element,
    isElementSelected,
    isCarouselSelected,
    index,
    selectElement,
  }: CarouselElementProps): JSX.Element => {
    const getEmptyStyle = (value?: string): CSSProperties => {
      if (value || isCarouselSelected) return {}
      return { color: COLORS.N500 }
    }
    if (isElementSelected) {
      return (
        <OpenedElement $hasBorder={index === 0} onClick={() => selectElement()}>
          <TextBodySmallBold style={getEmptyStyle(element.title)}>
            {element.title || 'Element title'}
          </TextBodySmallBold>
          {element.subtitle && (
            <MarkdownText isSelected={isElementSelected}>
              {element.subtitle}
            </MarkdownText>
          )}
          {element.image?.assetUrl && <img src={element.image.assetUrl} />}
          <Button button={element.buttons[0]} />
        </OpenedElement>
      )
    } else {
      return (
        <ClosedElement $hasBorder={index !== 0} onClick={() => selectElement()}>
          <TextBodySmall style={getEmptyStyle(element.title)}>
            {element.title || 'Element title'}
          </TextBodySmall>
          {element.image?.assetUrl && <img src={element.image.assetUrl} />}
          <StyledHandle
            handleType={HandleType.ELEMENT}
            type={NodeHandleType.Source}
            position={Position.Right}
            id={element.buttons[0].id}
            isHidden={!hasButtonHandle(element.buttons[0])}
          />
        </ClosedElement>
      )
    }
  }
)

interface ButtonProps {
  button: ButtonFields
  hasHandler?: boolean
  isReply?: boolean
  onClick?(): void
}
export const Button = memo(
  ({
    button,
    isReply,
    hasHandler = true,
    onClick,
  }: ButtonProps): JSX.Element => {
    const buttonUrl = button.url?.name
    const buttonPayload = button.payload?.name
    return (
      <ButtonContainer
        onClick={onClick}
        $isReply={isReply}
        $isEmpty={!button.text}
      >
        <Markdown>{button.text || 'Button'}</Markdown>
        {!isReply && buttonUrl && (
          <a href={buttonUrl} target='_blank' rel='noopener noreferrer'>
            <Icon icon='link' size='medium' />
          </a>
        )}
        {!buttonUrl && buttonPayload && <Icon icon='code' size='medium' />}
        {hasHandler && (
          <StyledHandle
            handleType={HandleType.BUTTON}
            type={NodeHandleType.Source}
            position={Position.Right}
            id={button.id}
            isHidden={!hasButtonHandle(button, isReply)}
          />
        )}
      </ButtonContainer>
    )
  }
)

interface ConditionProps {
  id: string
  children: React.ReactNode
  handleType?: HandleType
  icon?: IconName
  onClick?(): void
  border: Border | null
  isConnectableWithBotAction?: boolean
}
export const Condition = memo(
  ({
    id,
    children,
    icon,
    handleType,
    border,
    isConnectableWithBotAction = false,
    onClick,
  }: ConditionProps): JSX.Element => {
    const { isBotActionConnectable } = useBotConfig()
    isConnectableWithBotAction = isBotActionConnectable

    return (
      <ConditionContainer
        $border={border}
        $isSmallCondition={handleType === HandleType.SMALL_CONDITION}
        onClick={onClick}
      >
        {children}
        {icon && (
          <Icon
            icon={icon}
            size='large'
            prefix={icon === 'messages' ? 'fas' : 'fab'}
          />
        )}
        <StyledHandle
          handleType={handleType || HandleType.CONDITION}
          type={NodeHandleType.Source}
          position={Position.Right}
          id={id}
          isConnectableWithBotAction={isConnectableWithBotAction}
        />
      </ConditionContainer>
    )
  }
)

interface NodeSectionProps {
  title: string
  name?: string
  disableDivision?: boolean
}
export const NodeSection = memo(
  ({ name, title, disableDivision }: NodeSectionProps): JSX.Element => {
    return (
      <>
        {!disableDivision && <hr />}
        <div>
          <TextSmallBold>{title}</TextSmallBold>
          {name && (
            <TextBodySmall $wordBreak='break-word'>{name}</TextBodySmall>
          )}
        </div>
      </>
    )
  }
)

interface ChipListProps {
  items: string[]
  selected: boolean
}

export const ChipList = ({ items, selected }: ChipListProps) => {
  const remainingItems = items.length - 3
  return items.length > 0 ? (
    <StyledInputList>
      {items.slice(0, 3).map((item, index) => (
        <Badge key={index} intent={selected ? 'primary' : 'neutral'}>
          {item}
        </Badge>
      ))}
      {remainingItems > 0 && <TextSmall>+{remainingItems} more</TextSmall>}
    </StyledInputList>
  ) : (
    <></>
  )
}

interface StyledHandleProps extends HandleProps {
  handleType: HandleType
  isHidden?: boolean
  isConnectableWithBotAction?: boolean
}

export const StyledHandle = ({
  isConnectableWithBotAction = true,
  handleType,
  isHidden,
  ...props
}: StyledHandleProps): JSX.Element => {
  const { reportInform } = useFeedbackMessage()

  const isValidConnection = (connection: Connection): boolean => {
    if (
      connection.targetHandle?.includes(ContentType.BOT_ACTION) &&
      !isConnectableWithBotAction
    ) {
      reportInform('This content can not be connected to a bot action')
      return false
    }
    return true
  }

  return (
    <TriangleHandle
      {...props}
      $isHidden={isHidden}
      $type={handleType}
      isValidConnection={isValidConnection}
    >
      <ConnectionHandleIcon />
    </TriangleHandle>
  )
}

const hasButtonHandle = (button: ButtonFields, isReply?: boolean) => {
  return (!button.payload && !button.url) || (isReply && !button.payload)
}
