export const getAnalyticsWriteKey = (): string => {
  if (!import.meta.env.VITE_ANALYTICS_WRITE_KEY) {
    throw new Error('You need to set env var VITE_ANALYTICS_WRITE_KEY')
  }
  return import.meta.env.VITE_ANALYTICS_WRITE_KEY
}

export enum TrackEventName {
  ADD_NEW_NODE = 'fb_add_new_node',
  ADD_ONS = 'fb_add_ons',
  AGENT_AVAILABILITY = 'fb_agent_availability',
  AI_SETTINGS_OPEN = 'fb_ai_settings_open',
  AUTOASSIGN = 'fb_autoassign',
  BOTACTION_NEW_PAYLOAD = 'fb_botaction_new_payload',
  BOTACTION_PAYLOAD_DELETE = 'fb_botaction_payload_delete',
  BOTACTION_PAYLOAD_SELECT = 'fb_botaction_payload_select',
  CHANGE_INTENT_CONFIDENCE = 'fb_change_intent_confidence',
  CHANGE_LANGUAGE = 'fb_change_language',
  CHANGE_START_MESSAGE = 'fb_change_start_message',
  CLICK_CONTENT_PANEL = 'fb_click_content_panel',
  CLICK_DELETE_EDGE = 'fb_click_delete_edge',
  CLICK_LOCAL_ERROR_CTA = 'fb_click_local_error_CTA',
  CLICK_PUBLISH_CTA = 'fb_click_publish_CTA',
  CLICK_SAVE_CTA = 'fb_click_save_CTA',
  CLICK_VERSION = 'fb_click_version',
  CLICK_VIEW_EVERYTHING_CTA = 'fb_click_view_everything_CTA',
  CLICK_ZOOM_BUTTONS = 'fb_click_zoom_buttons',
  CONNECTION_RECOVERED = 'fb_connection_recovered',
  CONTENTID_AI_GENERATION = 'fb_contentid_AI_generation',
  CONTENTID_EDIT = 'fb_contentid_edit',
  CONTENTID_ERROR = 'fb_contentid_error',
  COPY_PASTE = 'fb_copy_paste',
  CUSTOM_CONDITION_VARIABLE = 'fb_custom_condition_variable',
  CUSTOM_CONDITION_VARIABLE_FORMAT = 'fb_custom_condition_variable_format',
  EXIT = 'fb_exit',
  FB_PREVIOUS_FLOWS = 'fb_previous_flows',
  FB_PREVIOUS_FLOWS_OPEN = 'fb_previous_flows_open',
  FLOW_ADD = 'fb_flow_add',
  FLOW_CONNECT = 'fb_flow_connect',
  FLOW_DELETE = 'fb_flow_delete',
  FLOW_OPEN = 'fb_flow_open',
  FLOW_RENAME = 'fb_flow_rename',
  FLOW_LIST_SEARCH_BAR = 'fb_flow_list_search_bar',
  GO_TO_FLOW_SEARCH_BAR = 'fb_go_to_flow_search_bar',
  KNOWLEDGE_BASE_ACTIVATION = 'fb_knowledge_base_activation',
  KNOWLEDGE_SOURCE_MISSING_ERROR = 'fb_knowledge_source_missing_error',
  LANGUAGE_SEARCH_BAR = 'fb_language_search_bar',
  LANGUAGES_ADD_REMOVE_CTA = 'fb_languages_add_remove_CTA',
  LANGUAGES_OPEN = 'fb_languages_open',
  PREVIEW = 'fb_preview',
  PREVIEW_COPY_LINK = 'fb_preview_copy_link',
  PREVIEW_INTEGRATIONS_SECTION = 'fb_preview_integrations_section',
  PREVIEW_OPEN = 'fb_preview_open',
  PREVIEW_WINDOW_COUNTRY = 'fb_preview_window_country',
  PREVIEW_WINDOW_LANGUAGE = 'fb_preview_window_language',
  PREVIEW_WINDOW_REFRESH = 'fb_preview_window_refresh',
  PUBLISH_CONFIRMATION = 'fb_publish_confirmation',
  REDO = 'fb_redo',
  SAVE = 'fb_save',
  SEARCH_BAR = 'fb_search_bar',
  SEARCH_BAR_ARROWS_CLICK = 'fb_search_bar_arrows_click',
  SEARCH_BAR_CONTENT_TYPES = 'fb_search_bar_content_types',
  SEARCH_BAR_RESET_FILTERS = 'fb_search_bar_reset_filters',
  SET_AI_MODEL = 'fb_set_ai_model',
  SET_CAROUSEL_COMPONENT = 'fb_set_carousel_component',
  SET_CUSTOM_CONDITION_VALUES = 'fb_set_custom_condition_values',
  SET_TEXT_COMPONENT_BUTTONS = 'fb_set_text_component_buttons',
  SMART_INTENT_TITLE_EDIT = 'fb_smart_intent_title_edit',
  SMART_INTENT_TITLE_ERROR = 'fb_smart_intent_title_error',
  UNDO = 'fb_undo',
  USER_ENDS_SESSION = 'fb_user_ends_session',
  USER_KICKED_OUT = 'fb_user_kicked_out',
  USER_SESSION_ENDED = 'fb_user_session_ended',
  VARIABLES_CREATE = 'fb_variables_create',
  VARIABLES_DELETE = 'fb_variables_delete',
  VARIABLES_OPEN = 'fb_variables_open',
  VARIABLES_SELECT = 'fb_variables_select',
  VERSION_CLICK_CANCEL_CTA = 'fb_version_click_cancel_CTA',
  VERSION_CLICK_CLOSE = 'fb_version_click_close',
  VERSION_CLICK_RESTORE_CONFIRMATION_CTA = 'fb_version_click_restore_confirmation_CTA',
  VERSION_CLICK_RESTORE_CTA = 'fb_version_click_restore_CTA',
  VERSION_HISTORY_OPEN = 'fb_version_history_open',
  TRANSFER_FLOW = 'fb_transfer_flow',
  FB_ERROR = 'fb_error',
  TRANSFER_FLOW_LIST_STATE = 'fb_transfer_flow_list_state',
}

export enum SaveOrigin {
  AUTO_SAVE = 'auto_save',
  ON_EXIT = 'on_exit',
  BEFORE_PUBLISH = 'before_publish',
  KEYBOARD = 'keyboard',
  ON_OPEN_PREVIOUS_VERSION = 'on_open_previous_version',
  ON_SESSION_ENDED = 'on_session_ended',
}
