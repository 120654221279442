import { Checkbox } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { InputAction } from '../constants'
import { EditorProps } from '../node-editor-panel'

interface FieldsCheckboxProps extends EditorProps {
  checked: boolean
  field: InputAction
}

export const FieldsCheckbox = memo((props: FieldsCheckboxProps) => {
  return (
    <Checkbox
      isSelected={props.checked}
      label={props.field.label}
      description={props.field.helperText}
      isReadOnly={props.isReadOnly}
      onChange={value =>
        props.onChange({
          type: props.field.actionType,
          fieldKey: props.field.key,
          value,
        })
      }
    />
  )
})
