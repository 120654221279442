import { MultiselectWithSections } from '@hubtype/ui-react-web'
import { useEffect, useState } from 'react'

import styles from './search-bar.module.css'
import { useContentTypesCount } from './use-content-types-count'

interface SelectContentTypeProps {
  selectedContentTypes: string[]
  onSelectionChange: (selectedValues: string[]) => void
  onClear: () => void
}

export const SelectContentType = ({
  selectedContentTypes,
  onSelectionChange,
  onClear,
}: SelectContentTypeProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { data } = useContentTypesCount(isDropdownOpen)

  const handleChange = (selectedValues: string[]) => {
    onSelectionChange(selectedValues)
  }

  const defaultLabel = 'All content types'
  const [label, setLabel] = useState(defaultLabel)
  useEffect(() => {
    if (selectedContentTypes.length === 0) {
      setLabel(defaultLabel)
      return
    }

    const items = data.flatMap(section => section.items)
    const firstSelectedName = items.find(
      item => item.id === selectedContentTypes[0]
    )?.name

    if (selectedContentTypes.length === 1) {
      setLabel(firstSelectedName!)
      return
    }

    setLabel(`${firstSelectedName} + ${selectedContentTypes.length - 1}`)
    return
  }, [selectedContentTypes])

  return (
    <MultiselectWithSections
      selectedKeys={selectedContentTypes}
      isLabelHidden={true}
      label={label}
      onClear={onClear}
      clearButtonText='Reset to all types'
      sections={data}
      onChange={handleChange}
      onOpenChange={setIsDropdownOpen}
      popoverProps={{ placement: 'bottom left' }}
      buttonProps={{ className: styles.dropdownButton }}
    >
      {item => (
        <div className={styles.dropdownItem}>
          {item.name} <span>({item.count})</span>
        </div>
      )}
    </MultiselectWithSections>
  )
}
