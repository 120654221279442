import {
  SearchBar,
  SelectItem,
  SelectSection,
  SelectWithSections,
} from '@hubtype/ui-react-web'
import { memo, useState } from 'react'

import {
  HandoffFields,
  QueueFields,
} from '../../../../../domain/models/content-fields'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { HtInfoPanel, TextBodyBold } from '../../../base'
import { StyledHeader } from '../../../flow-selector/flow-selector-styles'
import { Action, AUTO_ASSIGN, HANDOFF_PAYLOAD, QUEUE } from '../../constants'
import { FieldsCheckbox } from '../../editor-widgets/checkbox-widget'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'

interface HandoffEditorProps extends EditorProps {
  nodeContent: HandoffFields
}

export const HandoffEditor = memo(
  ({ nodeContent, ...props }: HandoffEditorProps): JSX.Element => {
    const analytics = useAnalytics()
    const { organizationContents } = useFlowBuilderSelector(ctx => ctx.state)
    const [flowFilter, setFlowFilter] = useState<string>('')

    const getQueueSelectItem = (): SelectSection<QueueFields>[] => {
      return organizationContents.projects.map(p => ({
        title: p.name,
        items: p.queues,
      }))
    }

    const onQueueChange = (value?: SelectItem) => {
      const change = { type: QUEUE.actionType, fieldKey: QUEUE.key, value }
      props.onChange(change)
    }

    const onAutoAssignChanges = (action: Action) => {
      const actionName = nodeContent.hasAutoAssign ? 'disabled' : 'enabled'
      analytics.trackEvent(TrackEventName.AUTOASSIGN, {
        action: actionName,
      })
      props.onChange(action)
    }

    // TODO. remove when we stop supporting old payloads
    const onRemovePayload = () => {
      const change = {
        type: HANDOFF_PAYLOAD.actionType,
        fieldKey: HANDOFF_PAYLOAD.key,
        value: undefined,
      }
      props.onChange(change)
    }

    return (
      <>
        <TextBodyBold>Agent handoff</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <SelectWithSections
          {...props}
          label={QUEUE.label}
          sections={getQueueSelectItem()}
          selectedKey={nodeContent.queue?.id}
          onChange={onQueueChange}
          filterValue={flowFilter}
          isReadOnly={props.isReadOnly}
          header={
            <StyledHeader>
              <SearchBar
                label='Search queue'
                defaultValue={flowFilter}
                size='small'
                onChangeWithDebounce={setFlowFilter}
              />
            </StyledHeader>
          }
        />
        <FieldsCheckbox
          {...props}
          field={AUTO_ASSIGN}
          checked={nodeContent.hasAutoAssign}
          onChange={onAutoAssignChanges}
        />
        <HtInfoPanel
          title='About agent handoff'
          text={
            'Once the user has been attended and the agent has closed the case, flow can be set to continue either through a bot action or moving on to the next conversational app component.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
